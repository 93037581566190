function sendGaEvent(
  eventLabel,
  eventAction = 'click',
  nonInteraction = false,
  eventCategory = window.location.pathname,
) {
  if (window.gtag) {
    window.gtag('event', eventAction, {
      event_category: eventCategory,
      event_label: eventLabel,
      non_interaction: nonInteraction,
    });
  } else {
    // eslint-disable-next-line no-console
    console.log('send GA event', {
      eventLabel,
      eventAction,
      nonInteraction,
      eventCategory,
    });
  }
}

module.exports = sendGaEvent;
