// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-template-index-jsx": () => import("./../src/components/Template/index.jsx" /* webpackChunkName: "component---src-components-template-index-jsx" */),
  "component---src-components-template-connect-jsx": () => import("./../src/components/Template/connect.jsx" /* webpackChunkName: "component---src-components-template-connect-jsx" */),
  "component---src-components-template-your-playlist-jsx": () => import("./../src/components/Template/your-playlist.jsx" /* webpackChunkName: "component---src-components-template-your-playlist-jsx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */)
}

