/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */

import React from 'react';

import closestParent from './src/utils/closestParent';
import sendGaEvent from './src/utils/sendGaEvent';
import App from './src/components/App';

export const wrapRootElement = ({ element }) => {
  const checkForGa = event => {
    const e = closestParent(event.target, '[data-event-label]', true);
    if (e) {
      const eventLabel = e.getAttribute('data-event-label');
      if (eventLabel) {
        sendGaEvent(eventLabel);
      } else {
        console.warn('missing event label on: ', e);
      }
    }
  };

  document.addEventListener('click', checkForGa, true);

  return <App>{element}</App>;
};
