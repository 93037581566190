import { createContext } from 'react';

export const initialState = {
  connectToken: null,
  playlist: null,
  first: null,
  second: null,
  third: null,
  journey: null,
  trips: [],
};

export const StateContext = createContext({ ...initialState, updateState: () => {} });
