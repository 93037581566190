import React from 'react';
import PropTypes from 'prop-types';

import { StateContext, initialState } from '../utils/globalState';

class App extends React.Component {
  constructor(props) {
    super(props);

    // create a function for updating the state
    this.stateUpdater = newState => {
      this.setState(() => ({ ...newState }));
    };

    // include the updater function in the state that
    // will be passed throughout the app
    // eslint-disable-next-line react/no-unused-state
    this.state = { ...initialState, updateState: this.stateUpdater };
  }

  render() {
    const { children } = this.props;

    return <StateContext.Provider value={this.state}>{children}</StateContext.Provider>;
  }
}

export default App;

App.propTypes = { children: PropTypes.node.isRequired };
